var render = function () {
  var _vm$selectedExerciseT;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.stillLoading ? _c('div', {
    staticClass: "loading-page"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Fetching Data ..."
    }
  })], 1) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.stillLoading,
      expression: "!stillLoading"
    }],
    staticClass: "min-h-screen"
  }, [_c('div', {
    staticClass: "mb-6 flex justify-between items-center"
  }, [_c('h1', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v(_vm._s(_vm.$route.meta.mode) + " Exercise")]), _c('Button', {
    attrs: {
      "buttonText": _vm.isEditMode ? 'Update' : 'Submit',
      "disabled": _vm.disableSubmit
    },
    on: {
      "action": _vm.submitData
    }
  })], 1), _c('div', {
    staticClass: "shadow-small rounded-lg bg-white mb-6"
  }, [_c('div', {
    staticClass: "flex items-stretch p-6"
  }, [_c('div', {
    staticClass: "flex-1 shadow-border-r flex-shrink-0 pr-6"
  }, [_vm.isEditMode ? _c('div', {
    staticClass: "mb-6"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Id",
      "borderEnabled": true,
      "disabled": ""
    },
    model: {
      value: _vm.options.id,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "id", $$v);
      },
      expression: "options.id"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "mb-6"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Exercice Title",
      "isLabelRequire": "",
      "borderEnabled": true,
      "placeholder": "Insert exercise title"
    },
    model: {
      value: _vm.options['name'],
      callback: function callback($$v) {
        _vm.$set(_vm.options, 'name', $$v);
      },
      expression: "options['name']"
    }
  })], 1), _vm.isDataReady ? _c('div', {
    staticClass: "mb-6"
  }, [_c('div', {
    staticClass: "desc editor"
  }, [_vm._m(0), _c('quill-editor', {
    ref: "desc",
    staticClass: "question-quill",
    attrs: {
      "options": {
        modules: _vm.quillOptionQuestion.modules,
        placeholder: 'Insert description here'
      }
    },
    on: {
      "ready": _vm.onEditorReady,
      "blur": _vm.onEditorBlur,
      "focus": _vm.onEditorFocus
    },
    model: {
      value: _vm.options['description'],
      callback: function callback($$v) {
        _vm.$set(_vm.options, 'description', $$v);
      },
      expression: "options['description']"
    }
  })], 1)]) : _vm._e()]), _c('div', {
    staticClass: "flex-1 flex-shrink-0 pl-6"
  }, [_c('div', {
    staticClass: "mb-6"
  }, [_vm._m(1), _c('Dropdown', {
    attrs: {
      "options": _vm.list_exercise_type,
      "optionLabel": "name",
      "default": _vm.selectedExerciseType,
      "placeholder": "Select Exercise Type"
    },
    model: {
      value: _vm.selectedExerciseType,
      callback: function callback($$v) {
        _vm.selectedExerciseType = $$v;
      },
      expression: "selectedExerciseType"
    }
  })], 1), _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.options.sort_by_number,
      expression: "options.sort_by_number"
    }],
    staticClass: "styled-checkbox",
    attrs: {
      "type": "checkbox",
      "id": "sortBy"
    },
    domProps: {
      "checked": Array.isArray(_vm.options.sort_by_number) ? _vm._i(_vm.options.sort_by_number, null) > -1 : _vm.options.sort_by_number
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.options.sort_by_number,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.options, "sort_by_number", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.options, "sort_by_number", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.options, "sort_by_number", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "text-sm",
    attrs: {
      "for": "sortBy"
    }
  }, [_vm._v("Sort by Number")])])])])]), ((_vm$selectedExerciseT = _vm.selectedExerciseType) === null || _vm$selectedExerciseT === void 0 ? void 0 : _vm$selectedExerciseT.id) !== 5 ? _c('div', {
    staticClass: "flex"
  }, [_vm._l(_vm.options.groups, function (group, index) {
    return _c('div', {
      key: 'groups-' + index,
      class: "ml-2 ".concat(index === _vm.activeGroup ? 'bg-white' : 'bg-grey-header', " px-6 py-2 rounded-t-lg flex items-center cursor-pointer"),
      on: {
        "click": function click($event) {
          return _vm.changeActiveGroup(index);
        }
      }
    }, [_c('p', {
      class: "".concat(index === _vm.activeGroup ? 'font-semibold' : '', " pr-2")
    }, [_vm._v("Group " + _vm._s(index + 1))]), _c('div', {
      on: {
        "click": function click($event) {
          return _vm.deleteGroup(index);
        }
      }
    }, [_c('Trash', {
      staticClass: "text-yellow hover:opacity-80",
      attrs: {
        "height": "20",
        "width": "20"
      }
    })], 1)]);
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.options.groups.length < 5,
      expression: "options.groups.length < 5"
    }],
    staticClass: "ml-2 bg-yellow-secondary hover:opacity-80 px-6 py-2 rounded-t-lg flex justify-center items-center cursor-pointer",
    on: {
      "click": function click($event) {
        return _vm.addGroup();
      }
    }
  }, [_c('p', {
    staticClass: "text-yellow font-semibold"
  }, [_vm._v("+ New Group")])])], 2) : _vm._e(), _c('div', {
    staticClass: "shadow-small rounded-lg bg-white"
  }, [_c('div', {
    staticClass: "p-6"
  }, [_vm.options.groups[_vm.activeGroup] ? _c('div', _vm._l(_vm.options.groups[_vm.activeGroup].exercise_detail, function (itemExerciseDetail, indexExerciseDetail) {
    var _itemExerciseDetail$a, _vm$selectedExerciseT2, _itemExerciseDetail$a2, _vm$selectedExerciseT3;

    return _c('div', {
      key: 'exerciseDetail-' + indexExerciseDetail,
      staticClass: "mb-6 last:mb-0"
    }, [_c('div', {
      staticClass: "flex justify-between items-center mb-4"
    }, [_c('label', {
      staticClass: "font-bold"
    }, [_vm._v(" Question " + _vm._s(indexExerciseDetail + 1) + " ")]), _c('div', {
      staticClass: "flex justify-center text-center gap-2"
    }, [_c('Button', {
      attrs: {
        "type": "delete",
        "size": "icon",
        "icon": function icon() {
          return import(
          /* webpackChunkName: 'icon' */
          '@/components/Icons/Trash');
        }
      },
      on: {
        "action": function action($event) {
          return _vm.deleteQuestion(indexExerciseDetail);
        }
      }
    })], 1)]), (itemExerciseDetail === null || itemExerciseDetail === void 0 ? void 0 : itemExerciseDetail.answer_type) === 'MULTIPLE_CHOICE' || (itemExerciseDetail === null || itemExerciseDetail === void 0 ? void 0 : (_itemExerciseDetail$a = itemExerciseDetail.answer_type) === null || _itemExerciseDetail$a === void 0 ? void 0 : _itemExerciseDetail$a.id) === 'MULTIPLE_CHOICE' ? _c('div', {
      staticClass: "p-3 rounded-lg bg-neutral-100"
    }, [_c('div', {}, [_c('div', {
      staticClass: "mb-4"
    }, [_c('div', {
      staticClass: "flex gap-2"
    }, [_c('div', {
      staticClass: "w-full"
    }, [_c('div', {
      staticClass: "editor"
    }, [_vm._m(2, true), _c('quill-editor', {
      ref: "desc",
      refInFor: true,
      staticClass: "question-quill",
      attrs: {
        "options": {
          modules: _vm.quillOptionQuestion.modules,
          placeholder: 'Insert question here'
        }
      },
      on: {
        "ready": _vm.onEditorReady,
        "blur": _vm.onEditorBlur,
        "focus": _vm.onEditorFocus
      },
      model: {
        value: itemExerciseDetail.question,
        callback: function callback($$v) {
          _vm.$set(itemExerciseDetail, "question", $$v);
        },
        expression: "itemExerciseDetail.question"
      }
    })], 1)]), _c('div', {
      staticClass: "w-40"
    }, [_c('TextField', {
      attrs: {
        "type": "number",
        "label": "Question value",
        "indexData": indexExerciseDetail,
        "min": "1",
        "isLabelRequire": "",
        "borderEnabled": true,
        "placeholder": "Question Value"
      },
      on: {
        "input": _vm.handleInput
      },
      model: {
        value: itemExerciseDetail.score_weight,
        callback: function callback($$v) {
          _vm.$set(itemExerciseDetail, "score_weight", $$v);
        },
        expression: "itemExerciseDetail.score_weight"
      }
    })], 1), _c('div', {
      staticClass: "w-44"
    }, [_c('label', {
      staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
    }, [_vm._v("Type Answer")]), _c('Dropdown', {
      attrs: {
        "options": _vm.typeAnswerList,
        "optionLabel": "name",
        "default": itemExerciseDetail.answer_type ? itemExerciseDetail.answer_type : _vm.typeAnswerList[0],
        "placeholder": "Select The Correct Answer",
        "disabled": ((_vm$selectedExerciseT2 = _vm.selectedExerciseType) === null || _vm$selectedExerciseT2 === void 0 ? void 0 : _vm$selectedExerciseT2.id) !== 5
      },
      model: {
        value: itemExerciseDetail.answer_type,
        callback: function callback($$v) {
          _vm.$set(itemExerciseDetail, "answer_type", $$v);
        },
        expression: "itemExerciseDetail.answer_type"
      }
    })], 1)]), !itemExerciseDetail.answer_true ? _c('p', {
      staticClass: "text-red text-xs mt-1"
    }, [_vm._v("Please select one of the answer on the radio button")]) : _vm._e()]), _vm._l(_vm.optionList, function (answer, answerIdx) {
      return _c('div', {
        key: 'answer-' + answerIdx,
        class: "mb-2 flex gap-4 relative items-center ".concat(answer === itemExerciseDetail.answer_true ? 'bg-yellow-secondary -mx-3 px-3 py-2' : '')
      }, [_c('div', {
        staticClass: "relative leading-0"
      }, [_c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: itemExerciseDetail.answer_true,
          expression: "itemExerciseDetail.answer_true"
        }],
        attrs: {
          "type": "radio",
          "disabled": !itemExerciseDetail[answer],
          "id": "answer-".concat(itemExerciseDetail.no, "-").concat(answer),
          "name": "answer-".concat(itemExerciseDetail.no)
        },
        domProps: {
          "value": answer,
          "checked": _vm._q(itemExerciseDetail.answer_true, answer)
        },
        on: {
          "change": function change($event) {
            return _vm.$set(itemExerciseDetail, "answer_true", answer);
          }
        }
      }), _c('label', {
        attrs: {
          "for": "answer-".concat(itemExerciseDetail.no, "-").concat(answer)
        }
      })]), _c('div', {
        staticClass: "w-full flex items-center gap-2"
      }, [_c('div', {
        staticClass: "uppercase"
      }, [_vm._v(_vm._s(answer) + ".")]), _c('div', {
        staticClass: "w-full"
      }, [_c('div', {
        staticClass: "editor"
      }, [_c('quill-editor', {
        ref: "desc",
        refInFor: true,
        staticClass: "question-quill",
        attrs: {
          "options": {
            modules: _vm.quillOptionQuestion.modules,
            placeholder: 'If left empty, will not be shown in LMS Student'
          }
        },
        on: {
          "ready": _vm.onEditorReady,
          "blur": _vm.onEditorBlur,
          "focus": _vm.onEditorFocus,
          "change": function change() {
            return !itemExerciseDetail[answer] && answer === itemExerciseDetail.answer_true ? itemExerciseDetail.answer_true = '' : itemExerciseDetail.answer_true = itemExerciseDetail.answer_true;
          }
        },
        model: {
          value: itemExerciseDetail[answer],
          callback: function callback($$v) {
            _vm.$set(itemExerciseDetail, answer, $$v);
          },
          expression: "itemExerciseDetail[answer]"
        }
      })], 1)])])]);
    }), _c('div', {
      staticClass: "w-full mt-4"
    }, [_c('div', {
      staticClass: "editor feedback"
    }, [_c('label', {
      staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
    }, [_vm._v("Feedback (optional)")]), _c('quill-editor', {
      ref: "desc",
      refInFor: true,
      staticClass: "question-quill",
      attrs: {
        "options": {
          modules: _vm.quillOptionQuestion.modules,
          placeholder: 'Insert feedback here'
        }
      },
      on: {
        "ready": _vm.onEditorReady,
        "blur": _vm.onEditorBlur,
        "focus": _vm.onEditorFocus
      },
      model: {
        value: itemExerciseDetail.reference,
        callback: function callback($$v) {
          _vm.$set(itemExerciseDetail, "reference", $$v);
        },
        expression: "itemExerciseDetail.reference"
      }
    })], 1)])], 2)]) : _vm._e(), (itemExerciseDetail === null || itemExerciseDetail === void 0 ? void 0 : itemExerciseDetail.answer_type) === 'FREE_TEXT' || (itemExerciseDetail === null || itemExerciseDetail === void 0 ? void 0 : (_itemExerciseDetail$a2 = itemExerciseDetail.answer_type) === null || _itemExerciseDetail$a2 === void 0 ? void 0 : _itemExerciseDetail$a2.id) === 'FREE_TEXT' ? _c('div', {
      staticClass: "flex rounded-lg p-3 gap-2 w-full bg-neutral-100"
    }, [_c('div', {
      staticClass: "w-full"
    }, [_c('div', {
      staticClass: "editor"
    }, [_vm._m(3, true), _c('quill-editor', {
      ref: "desc",
      refInFor: true,
      staticClass: "question-quill",
      attrs: {
        "options": _vm.quillOptionQuestion
      },
      on: {
        "ready": _vm.onEditorReady,
        "blur": _vm.onEditorBlur,
        "focus": _vm.onEditorFocus
      },
      model: {
        value: itemExerciseDetail.question,
        callback: function callback($$v) {
          _vm.$set(itemExerciseDetail, "question", $$v);
        },
        expression: "itemExerciseDetail.question"
      }
    })], 1)]), _c('div', {
      staticClass: "w-40"
    }, [_c('TextField', {
      attrs: {
        "type": "number",
        "label": "Question value",
        "min": "1",
        "isLabelRequire": "",
        "borderEnabled": true,
        "placeholder": "Question Value"
      },
      on: {
        "input": _vm.handleInput
      },
      model: {
        value: itemExerciseDetail.score_weight,
        callback: function callback($$v) {
          _vm.$set(itemExerciseDetail, "score_weight", $$v);
        },
        expression: "itemExerciseDetail.score_weight"
      }
    })], 1), _c('div', {
      staticClass: "w-48"
    }, [_c('label', {
      staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
    }, [_vm._v("Type Answer")]), _c('Dropdown', {
      attrs: {
        "options": _vm.typeAnswerList,
        "optionLabel": "name",
        "default": itemExerciseDetail.answer_type ? itemExerciseDetail.answer_type : _vm.typeAnswerList[0],
        "placeholder": "Select The Correct Answer",
        "disabled": ((_vm$selectedExerciseT3 = _vm.selectedExerciseType) === null || _vm$selectedExerciseT3 === void 0 ? void 0 : _vm$selectedExerciseT3.id) !== 5
      },
      model: {
        value: itemExerciseDetail.answer_type,
        callback: function callback($$v) {
          _vm.$set(itemExerciseDetail, "answer_type", $$v);
        },
        expression: "itemExerciseDetail.answer_type"
      }
    })], 1)]) : _vm._e()]);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "flex justify-start mt-6"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Add Question",
      "type": "secondary"
    },
    on: {
      "action": _vm.addQuestion
    }
  })], 1)])])]), _c('Modal', {
    attrs: {
      "modalVisible": _vm.visibleWarning,
      "id": "warning-modal",
      "closeIconVisible": true
    },
    on: {
      "close": function close($event) {
        return _vm.toggleWarning();
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('p', {
    staticClass: "text-2xl font-bold mb-2"
  }, [_vm._v(_vm._s(_vm.warningTitle))]), _c('p', {
    staticClass: "mb-8 text-sm"
  }, [_vm._v(_vm._s(_vm.warningMessage))]), _c('Button', {
    attrs: {
      "buttonText": "Got it",
      "type": "primary"
    },
    on: {
      "action": _vm.toggleWarning
    }
  })], 1)], 2)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Description"), _c('span', {
    staticClass: "text-red"
  }, [_vm._v(" *")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Exercise Type "), _c('span', {
    staticClass: "text-red"
  }, [_vm._v(" *")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Question Title "), _c('span', {
    staticClass: "text-red"
  }, [_vm._v(" *")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Question Title "), _c('span', {
    staticClass: "text-red"
  }, [_vm._v(" *")])]);
}]

export { render, staticRenderFns }