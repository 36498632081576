import { render, staticRenderFns } from "./ExerciseForm.vue?vue&type=template&id=4b592dea&scoped=true&"
import script from "./ExerciseForm.vue?vue&type=script&lang=js&"
export * from "./ExerciseForm.vue?vue&type=script&lang=js&"
import style0 from "./ExerciseForm.vue?vue&type=style&index=0&id=4b592dea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b592dea",
  null
  
)

export default component.exports